.menu {
    background-color: #164a41;
}

.brand-link {
    padding-left: 60px;
    text-decoration: underline !important;
    text-decoration-color: #0f3831 !important;
    text-underline-offset: 5px !important;

    &:hover {
        text-decoration-color: #306d63 !important;
    }
}

.navbar-collapse.collapse,
.navbar-toggler.collapsed {
    margin-right: 35px;
}
