.container-home {
    background-color: #164a41;
    padding: 60px;
    padding-left: 60px !important;
    padding-right: 60px !important;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-end;
    justify-content: center;
    height: calc(100% - 69px);
    color: white;

    &.centered {
        align-items: center;
    }

    &.full-height {
        height: 100vh;
    }

    > div {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
}

.container-loading {
    align-items: center;
    justify-content: center;
    height: 100vh;
    display: flex;
}

.title-one {
    font-size: 28pt;
    transform: rotate(90deg);
    align-self: flex-end;
    margin-right: -200px;
    padding-left: 185px;
}

.title-two {
    letter-spacing: -15px;
    text-transform: uppercase;
    font-size: 170pt;
    line-height: 160px;
    color: #ffffff;
}

.not-found {
    letter-spacing: -2px;
    text-transform: uppercase;
    font-size: 86pt;
    line-height: 160px;
    color: #ffffff;
}
