.MuiFormControl-fullWidth {
    margin-top: 15px !important;
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    > svg {
        cursor: pointer;
    }
}
