.container-links {
    background-color: #164a41;
    padding: 60px;
    padding-left: 70px !important;
    padding-right: 60px !important;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    height: auto;
    color: white;

    > .idea-box {
        display: flex;
        margin-top: 10px;
        background-color: #f3f3f3;
        border: 1px solid #b4b4b4;
        box-shadow: 2px 2px 2px #a3a3a3;
        height: 120px;
        width: 100%;
        color: #164a41;
        padding: 10px;

        > .fields {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 100%;

            > .link-title {
                // cursor: pointer;
            }

            > .link-type {
                font-weight: 600;
            }
        }

        > .actions {
            justify-content: flex-end;
            align-items: flex-start;
            display: flex;
            cursor: pointer;
        }

        #containerQr
        {
            display: "block";
            margin-right: 10px;
        }

        #btnQrCode
        {
            width: 24px;
            height:24px;
            margin-top: -2px;
            margin-right: 5px;
        }
    }

    h1 {
        margin-bottom: 0 !important;
    }
}

.page-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.no-items {
    width: 100%;
    text-align: center;
    font-size: 10pt;
}
