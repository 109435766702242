.MuiFormControl-fullWidth {
    margin-top: 15px !important;
}

.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > button {
        margin-top: 0 !important;
    }

    > svg {
        cursor: pointer;
    }
}
