.icon-default {
    color: #164a41;
    border: 1px solid #164a41;
    padding: 2px;
    margin-right: 5px;

    &:hover {
        box-shadow: 1px 1px 1px 1px
    }
}

.copy-dc {
    color: #F9864A;
    border: 1px solid #344BF0;
    padding: 2px;
    margin-right: 5px;

    &:hover {
        box-shadow: 1px 1px 1px 1px
    }
}

.copy-pp {
    color: #009739;
    border: 1px solid red;
    padding: 2px;

    &:hover {
        box-shadow: 1px 1px 1px 1px
    }
}