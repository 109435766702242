@import "node_modules/bootstrap/scss/bootstrap";

.App {
  text-align: center;

  .App2 {
    text-align: right;
  }

  > .App-header {
    &:not(.App2) {
      background-color: black;
    }
  }
}

.error {
  color: red;
  font-size: 8pt;
  margin-top: 10px;
}

.inputAuto {
  margin: auto;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.form-horizontal .control-label {
  text-align: left;
}

.navBar {
  padding-bottom: 69px;
}

.Content {
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
  padding: 80px;
  padding-right: 60px !important;
  padding-left: 60px !important;
  border: 12px solid #0c312b;
  background-color: #164a41;
  color: white;
  border-radius: 30px;
  box-sizing: border-box;
  box-shadow: 10px 5px 5px #9da7a5;
  margin: 20px;
}
